import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image';

const BlogCard = ({data}) => {

    const {titulo,imagenDeLaEntrada,fecha,descripcion,enExhibicion, tituloDeRespaldo} = data;

    var normalize = (function() {
        var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,", 
        to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};
        
        for(var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );
        
        return function( str ) {
            var ret = [];
            for( var i = 0, j = str.length; i < j; i++ ) {
                var c = str.charAt( i );
                if( mapping.hasOwnProperty( str.charAt( i ) ) )
                ret.push( mapping[ c ] );
                else if ((str[i]===" "))
                ret.push( '-' );
                else if ((str.length-1 === i) && (str[str.length-1]===" "))
                ret.push( '-' );
                else
                ret.push( c );
            }      
            return ret.join( '' );
        }
        
    })();
    return (
        <div className="col-lg-6 col-xl-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <Link to={`/arteycultura/${normalize(titulo).toLowerCase()}`}>
                <div className="service-item">
                    <div className="service-img">
                        {  enExhibicion ? 
                            <div className="exhibicion">EN EXHIBICIÓN</div> :
                            null
                        }
                        <Image fluid={imagenDeLaEntrada.fluid} alt={descripcion}/>
                        <div className={"date" + (enExhibicion ? '-new' : '')}><p>{fecha}</p></div>
                    </div>
                    <div className={"service-text" + (enExhibicion ? '-new' : '')}>
                        <p className="tittle">{tituloDeRespaldo || titulo}</p>
                        <p className={"service-parrafo" + (enExhibicion ? '-new' : '')}>{descripcion}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default BlogCard
