import React, { useState } from 'react'
import { Link } from 'gatsby'
import arrow_down from '../../styles/icons/drop-down-arrow.svg'
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from '../cards/blogcard'

const BlogCategories = ({ Categoria }) => {
    const Datos = useStaticQuery(
        graphql`
          query {
            allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}) {
                nodes {
                    blogSubtitulo
                }
              }
            }
        `)

    const [menuDropdown, setMenuDropdown] = useState(false)
    const CategoriesClick = () => {
        setMenuDropdown(prevValue => !prevValue);
    }

    return (
        <div>
            <div className="service arte-y-cultura">
                <div className="service-subcontainer">
                    <div className="menu-left">
                        <div style={{ 'height': '100px' }}>
                            <h1>+ARTE Y CU<span>LTURA</span></h1>
                        </div>
                        <div className="subtittle">
                            {Datos.allContentfulGeneral.nodes[0].blogSubtitulo ?

                                <p>{Datos.allContentfulGeneral.nodes[0].blogSubtitulo}.
                                </p> : <p>Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural.</p>
                            }
                            <p>Toma en cuenta las siguientes categorías:</p>
                            <ul>
                                <li><Link to={"/agendacultural"}>Agenda Cultural</Link></li>
                                <li><Link to={"/artesaudiovisuales"}>Artes audiovisuales</Link></li>
                                <li><Link to={"/artesescenicas"}>Artes escénicas</Link></li>
                                <li><Link to={"/artesplasticas"}>Artes plásticas</Link></li>
                                <li><Link to={"/literaturaypublicaciones"}>Literatura y publicaciones</Link></li>
                                <li><Link to={"/disenoyartesvisuales"}>Diseño y artes visuales</Link></li>
                                <li><Link to={"/enexhibicion"}>En exhibición</Link></li>
                                <li><Link to={"/musicaycreacionsonora"}>Música y creación sonora</Link></li>
                                <li><Link to={"/patrimonio"}>Patrimonio</Link></li>
                                <li><Link to={"/quienesomos"}>Quiénes somos</Link></li>
                                <li><Link to={"/sellobicentenario"}>Sello Bicentenario</Link></li>
                                <li><Link to={"/selloupc"}>Sello UPC</Link></li>
                                <li><Link to={"/upccultural"}>UPC Cultural &#174;</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container service-container">
                    <div className="title_responsive">
                        <h1>+ARTE Y CULTURA</h1>
                        {Datos.allContentfulGeneral.nodes[0].blogSubtitulo ?
                            <p>{Datos.allContentfulGeneral.nodes[0].blogSubtitulo}.
                            </p> : <p>Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural.</p>
                        }
                    </div>
                    <div className="section-header">
                        <nav className="navigation">
                            <ul className="mainmenu">
                                <li onClick={CategoriesClick} tabIndex={-42} key={1} role='presentation'><h1>CATEGORÍAS
                                    <img src={arrow_down} alt="imagen de flecha" /></h1>
                                    <ul className={"submenu" + (menuDropdown ? ' listactive' : '')}>
                                        <li><Link to={"/agendacultural"}>Agenda Cultural</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/artesaudiovisuales"}>Artes audiovisuales</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/artesescenicas"}>Artes escénicas</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/artesplasticas"}>Artes plásticas</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/literaturaypublicaciones"}>Literatura y publicaciones</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/disenoyartesvisuales"}>Diseño y artes visuales</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/enexhibicion"}>En exhibición</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/musicaycreacionsonora"}>Música y creación sonora</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/patrimonio"}>Patrimonio</Link><div className="hr_submenu"></div></li>
                                        <li><Link to={"/quienesomos"}>Quiénes somos</Link></li>
                                        <li><Link to={"/sellobicentenario"}>Sello Bicentenario</Link></li>
                                        <li><Link to={"/selloupc"}>Sello UPC</Link></li>
                                        <li><Link to={"/upccultural"}>UPC Cultural &#174;</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>


                    </div>
                    <div className="row container-blog-img-responsive">
                        {Categoria.allContentfulArteYCultura.nodes.length === 0 ? <p className="text-page-not-found">Pronto aquí <br></br> +Arte y Cultura</p> :

                            <React.Fragment>
                                {
                                    Categoria.allContentfulArteYCultura.nodes.map(Data => (
                                        <BlogCard data={Data} key={Data.id}>
                                        </BlogCard>
                                    ))
                                }
                            </React.Fragment>

                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCategories
